.outer a {
  color: #a6071a;
}
.outer a:hover {
  text-decoration: underline;
}

.outer ul {
  list-style: disc;
}

.outer ol {
  list-style: decimal;
}

.outer ul,
.outer ol {
  padding-left: 40px;
  font-size: 1.1rem;
  line-height: 40px;
}
.outer .list-item {
  line-height: normal;
  font-size: 1.1rem;
  padding-left: 0px;
  color: red;
}
.outer p {
  font-size: 1.1rem;
  line-height: 40px;
}
.outer hr {
  margin-top: 15px;
  padding-bottom: 5px;
}
.outer h1 {
  font-size: 2.23em;
  font-weight: normal;
  font-family: Georgia, serif;
  margin-top: 20px;
}
.outer h2 {
  font-size: 1.6rem;
  font-weight: normal;
  font-family: Georgia, serif;
}
.outer h3 {
  font-size: 1.4rem;
  font-weight: normal;
  font-family: Georgia, serif;
}
.outer h4 {
  font-size: 1.2rem;
  font-weight: normal;
  font-family: Georgia, serif;
}

.outer table {
  border-collapse: collapse; /* separate cell borders */
  width: 100%;
  border: 2px solid black; /* set border around table */
}

.outer th,
.outer td {
  padding: 8px; /* set cell padding */
  text-align: left; /* set cell text alignment */
  border-bottom: 1px solid #ddd; /* set bottom cell border */
  border-right: 1px solid #ddd; /* set right cell border */
}

.outer th {
  background-color: #f2f2f2;
}
