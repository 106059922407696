.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #f5f5f5;
}

.react-flow__panel.react-flow__attribution.bottom.right {
  display: none;
}

.box {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.box:hover .centered {
  opacity: 1;
}

html {
  -webkit-tap-highlight-color: transparent;
}

/* for navbar */

.link {
  position: relative;
  text-decoration: none;
  color: #333;
  display: inline-block;
  line-height: 1.25;
}
.link:not(.selected-link):after {
  content: '';
  display: block;
  border-bottom: solid 2px #0076ff;
  transform: scaleX(0);
  transition: transform 200ms ease-in-out;
  transform-origin: 100% 50%;
}
.link:not(.selected-link):hover:after {
  transform: scaleX(1);
  transform-origin: 0 50%;
}

.selected-link {
  border-bottom: solid 2px #0076ff;
}
